// @ts-nocheck <-- ignore undefined "process" errors
import * as Sentry from '@sentry/browser'

if (process.env.SENTRY_DSN) {
  const sentryConfig = { dsn: process.env.SENTRY_DSN } as {
    dsn: string
    release?: string
  }
  if (process.env.SENTRY_RELEASE) {
    sentryConfig.release = process.env.SENTRY_RELEASE
  }
  Sentry.init(sentryConfig)
}

void import('./App.svelte').then(
  ({ default: App }) => new App({ target: document.getElementById('app') as HTMLElement }),
)
